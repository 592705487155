<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Idoneità covid</ion-title>
      </ion-toolbar>
    </ion-header> 
    <ion-content :fullscreen="true">
      <div id="container">
        <ion-list>
          <ion-radio-group value=0 v-model="idoneity.ask1">
            <ion-list-header>
              <ion-label>Hai una temperatura superiore a 37.5°?</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-label>Sì</ion-label>
              <ion-radio slot="start" value=1></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>No</ion-label>
              <ion-radio slot="start" value=2></ion-radio>
            </ion-item>
          </ion-radio-group>
          <ion-radio-group value=0 v-model="idoneity.ask2">
            <ion-list-header>
              <ion-label>Hai sintomi influenzali(tosse, mal di gola, raffreddore, stanchezza)?</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-label>Sì</ion-label>
              <ion-radio slot="start" value=1></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>No</ion-label>
              <ion-radio slot="start" value=2></ion-radio>
            </ion-item>
          </ion-radio-group>
          <ion-radio-group value=0 v-model="idoneity.ask3">
            <ion-list-header>
              <ion-label>Hai avuto contatti con persone risultate positive negli ultimi 14 giorni?</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-label>Sì</ion-label>
              <ion-radio slot="start" value=1></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>No</ion-label>
              <ion-radio slot="start" value=2></ion-radio>
            </ion-item>
          </ion-radio-group>
          <!--
          <ion-radio-group value=0 v-model="idoneity.ask4">
            <ion-list-header>
              <ion-label>Hai preso mezzi pubblici per recarti al lavoro?</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-label>Sì</ion-label>
              <ion-radio slot="start" value=1></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>No</ion-label>
              <ion-radio slot="start" value=2></ion-radio>
            </ion-item>
          </ion-radio-group>
          <ion-radio-group value=0 v-model="idoneity.ask5">
            <ion-list-header>
              <ion-label>Disponi dei dispositivi di protezione individuale(mascherina, disinfettante..)?</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-label>Sì</ion-label>
              <ion-radio slot="start" value=1></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>No</ion-label>
              <ion-radio slot="start" value=2></ion-radio>
            </ion-item>
          </ion-radio-group>
          -->
        </ion-list> 
        <br>
        <ion-button @click="newQuestionario()" v-if="idoneity.ask1!=0 && idoneity.ask2!=0 && idoneity.ask3!=0">
          Invia verifica accesso
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButton,
    IonButtons, 
    IonContent, 
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader, 
    IonMenuButton, 
    IonPage,
    IonRadio,
    IonRadioGroup, 
    IonTitle, 
    IonToolbar,
    toastController
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonButton,
      IonButtons,
      IonContent,
      IonHeader,
      IonItem,
      IonLabel,
      IonList,
      IonListHeader,
      IonMenuButton,
      IonPage,
      IonRadio,
      IonRadioGroup,
      IonTitle,
      IonToolbar
    },
    data : function(){
      return{
        idoneity:{
          user:0,
          ask1:0,
          ask2:0,
          ask3:0,
         // ask4:0,
        //ask5:0,
        },
        user:{
          name:'',
          email:'',
          role:0,
          company:'',
          password:'',
          quest_covid:'',
        },
      }
    },
    methods:{
      getUserInfo(){
        this.axios.post(this.apiUrl + 'get-userInfo', {}, this.headers)
        .then(async (response) => {
          this.user=response.data.user;
          var now=response.data.now;

          if(this.user.quest_covid==now){
            this.$router.push('dashboard');
          }
        })
      },
      getUser(){
        this.axios.post(this.apiUrl + 'get-user', {}, this.headers)
        //.then(async (response) => {
        //  console.log(response)
        //})
      },
      newQuestionario(){
        this.axios.post(this.apiUrl + 'save-idoneity', {idoneity:this.idoneity}, this.headers)
        .then(async (response) => {
            if(response.data.status_code==200){
              const toast = await toastController
              .create({
                  color: 'success',
                  message: response.data.message,
                  duration: 2000
              })
              this.$router.push('/folder/dashboard');
              return toast.present();
            }
          })
      },
    },
    ionViewWillEnter: function(){
      this.getUserInfo();
      this.getUser();
    }
  }
</script>

<style scoped>
  #container {
    min-height:100%;
    display:block;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
  }
  ion-button{
     float:right;
     width:40%;
  }
  ion-label{
    font-size:17px;
  }
  .sc-ion-buttons-ios-h
  {
    display:none;
  }
 @media only screen and (max-width: 400px) {
  ion-button{
     float:right;
     width:70%;
  }
}
</style>